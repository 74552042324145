<script>
export default {
  mounted() {
  }
}
</script>

<template>
  <div>
    <div id="preloader"></div>
    <a href="/" className="back-to-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></a>
  </div>
</template>
